import http, { get, post, postFrom } from '@/utils/http'
export const address = 'https://sc.bssznyfwzx.com/'
export function GetCommodityHotList() {
    return postFrom(`/api/GetCommodityHotList`,
        {
            'TradeID': 87504
        },
    )

}
// 根据条件查询商品列表
export function GetCommodityOnSaleListByCondition(keyword, categories) {
    return postFrom(`/api/GetCommodityOnSaleListByCondition`,
        {
            'TradeID': 87504,
            'Keyword': keyword,
            'Categories': categories
        },
    )
}
// 获取商品分类列表
export function getCategoriesList() {
    return post(`/api/GetCommodityClassifyTreeOnSale`, { TradeID: 87504 })
}


//获取订单列表
export function getOrderList(WeOpenId, type, time) {
    return postFrom(`/api/PublicGetPurchaserOrdersList`,
        {
            EnWeChatOpenID: WeOpenId,
            Type: type,
            TradeID: 87504,
            CutOffTime: time
        })
}

//获取订单详情
export function getOrderDetail(EnWeChatOpenID, OrderID) {
    return postFrom(`/api/PublicGetPurchaserOrdersInfo`,
        {
            EnWeChatOpenID: EnWeChatOpenID,
            OrderID: OrderID,
            TradeID: "87504"
        })
}
//获取收货地址列表
export function getAddressList(WeOpenId) {
    return postFrom(`/api/PublicGetAddressList`,
        {
            EnWeChatOpenID: WeOpenId,
            Parameter: 0,
            TradeID: 87504,
        })
}
//添加商品到购物车
export function addToCart(params) {
    return postFrom(`/api/SaveShoppingCart`, params)
}
//获取购物车商品列表
export function getProductList(params) {
    return postFrom(`/api/GetShoppingCartTree`, params)
}
//修改商品数量
export function changeProductQuantity(params) {
    return postFrom(`/api/UpdateShoppingCart`, params)
}
//从购物车移除商品
export function removeProductFromShoppingCart(params) {
    return postFrom(`/api/DeleteShoppingCart`, params)
}



