export default {
    namespaced: true,
    state: {
        userInfo: JSON.parse(localStorage.getItem('userInfo')),
    },
    mutations: {
        SET_USER_INFO(state, userInfo) {
            state.userInfo = userInfo
        },
    },
    actions: {
        SaveUserInfo({ commit }, res) {
            commit('SET_USER_INFO', res.Data.Result)
            localStorage.setItem('userInfo', JSON.stringify(res.Data.Result))
        },
        LogOut({ commit }) {
            commit('SET_USER_INFO', {})
            localStorage.removeItem('userInfo')
        },

    },
    getters: {}
}