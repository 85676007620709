import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import MetaInfo from 'vue-meta-info'
import * as echarts from 'echarts'
//验证是否登录的mixins
import loginMixins from "@/mixins/loginMixins";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$echarts = echarts
Vue.mixin(loginMixins)
Vue.use(ElementUI)
Vue.use(MetaInfo)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
