import axios from "axios";
export const baseURL = '/api';   //http://116.55.233.249:9001/api/
export const codeURL = '/api/BNWeb';  //http://116.55.233.249:9004/api/BNWeb/
export const prodURL = '/api';  // https://sc.bssznyfwzx.com/api/


const http = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout: 3000
})

http.interceptors.request.use()
http.interceptors.response.use()

export function get(url, params) {
    return new Promise((resolve, reject) => {
        http.get(url, { params: params }).then(res => {
            resolve(res.data)
        })
            .catch(err => {
                reject(err.data)
            })
    })
}
export function post(url, data) {
    return new Promise((resolve, reject) => {
        http.post(url, JSON.stringify(data)).then(res => {
            resolve(res.data)
        })
            .catch(err => {
                reject(err.data)
            })
    })
}
export function postFrom(url, data) {
    return new Promise((resolve, reject) => {
        http.post(url, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        ).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}
export default http


