import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/index',
  },
  {
    path: "/login",
    component: () => import('@/views/login/Login.vue'),
  },
  {
    path: "/index",
    component: () => import('@/views/index/Index.vue'),
  },
  {
    path: "/news",
    component: () => import('@/views/news/News.vue'),
  },
  {
    path: "/newsDetail",
    component: () => import('@/views/news/NewsDetail.vue'),
  },
  {
    path: "/asset",
    component: () => import('@/views/asset/AssetManager.vue'),
  },
  // 农资商城首页
  {
    path: "/transaction",
    component: () => import('@/views/transaction/Transaction.vue'),
  },
  // 商品列表
  {
    path: "/productlist",
    component: () => import('@/views/productlist/ProductList.vue'),
  },
  //商品详情
  {
    path: "/productdetails",
    component: () => import('@/views/productDetails/ProductDetails.vue'),
  },
  // 订单
  {
    path: "/order",
    component: () => import('@/views/order/Order.vue'),
  },
  // 支付
  {
    path: "/pay",
    component: () => import('@/views/pay/Pay.vue'),
  },
  // 购物车
  {
    path: "/shoppingcart",
    component: () => import('@/views/shoppingCart/ShoppingCart.vue'),
  },
  {
    path: "/agrprotrading",
    component: () => import('@/views/agrProTrading/AgrProTrading.vue'),
  },
  //用户中心
  {
    path: "/usercenter",
    component: () => import('@/views/userCenter/UserCenter.vue'),
  },
  {
    path: "/hrefWx",
    component: () => import('@/views/hrefWx/hrefWx.vue'),
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path.includes('usercenter')) {
    let token = localStorage.getItem('userInfo');
    // 判断userInfo是否为空如果为空则跳转到登录页 如果有则放行
    if (token === null || token === '') {
      next({ path: '/login' });
    } else {
      next();
    }
  } else if (to.path.includes('shoppingcart')) {
    let token = localStorage.getItem('userInfo');
    // 判断userInfo是否为空如果为空则跳转到登录页 如果有则放行
    if (token === null || token === '') {
      next({ path: '/login' });
    } else {
      next();
    }
  } else if (to.path.includes('pay')) {
    let token = localStorage.getItem('userInfo');
    // 判断userInfo是否为空如果为空则跳转到登录页 如果有则放行
    if (token === null || token === '') {
      next({ path: '/login' });
    } else {
      next();
    }
  } else if (to.path.includes('order')) {
    let token = localStorage.getItem('userInfo');
    // 判断userInfo是否为空如果为空则跳转到登录页 如果有则放行
    if (token === null || token === '') {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
