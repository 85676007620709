import { mapActions} from "vuex";

export default {
    computed: {
        IsLogin() {
            return localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).LoginFlag ? true : false
        }
    },
    methods: {
        ...mapActions('user',['LogOut']),
        jumpLogin() {
            this.LogOut();
            sessionStorage.setItem('path', this.$route.fullPath)
            this.$router.push('/login')
        }
    }
}
