import { getCategoriesList } from '@/api/shop'
export default {
    namespaced: true,
    state() {
        return {
            categoryList: {}
        }
    },
    mutations: {
        setCategoryList(state, res) {
            state.categoryList = res
        }
    },
    actions: {
    },
    getters: {}
}
