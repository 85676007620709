<template>
  <div id="app" :style="appStyle">
    <div class="adaptive-box">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { getCategoriesList } from "@/api/shop";
export default {
  computed: {
    background() {
      return this.$route.path == "/transaction" ||
        this.$route.path == "/agrprotrading"
        ? "#ffffff"
        : "#f3f5fa";
    },
  },
  data() {
    return {
      timer: null,
      scale: 1,
      appStyle: {
        background: this.background,
      },
    };
  },
  beforeMount() {
    this.setScale();
    window.addEventListener("resize", this.setScale);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setScale);
  },
  methods: {
    setScale() {
      const that = this;
      if (that.timer !== null) {
        clearTimeout(that.timer);
        that.timer = null;
      }
      that.timer = setTimeout(function () {
        let container = document.querySelector(".adaptive-box");
        if (devicePixelRatio === 1) {
          that.scale = document.documentElement.clientWidth / 1920;
          container.style.transform = `scale(${that.scale})`;
        }
        container.style.margin = `0 auto`;
      }, 16);
    },
  },
};
</script>
<style lang="less">
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
  position: relative;
  .adaptive-box {
    width: 1920px;
    min-height: 100vh;
    height: auto;
    transform-origin: 0 0;
    margin: 0 auto;
    transition: all 0s linear;
  }
}
</style>
